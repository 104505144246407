/*
 *  index.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
import React, {Suspense} from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import './i18n';

ReactDOM.render( <Suspense fallback={<div>Loading...</div>}><App /></Suspense>, document.getElementById('root'));
