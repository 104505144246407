/*
 *  context.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
import {Component} from "react";
import i18n from './i18n';
import collaborationExceptions from "./exceptions";
import CryptoJS from 'crypto-js';

class Context extends Component {
    static username = "";
    static password = "";
    static language = "";
    static backendURL = "";
    static api = [];

    // ---------------------------------

    static crypto = require('crypto');
    static ENC_KEY = "bf3c199c2470cb477d907b1e0917c17b"; // set random encryption key
    static IV = "5183666c72eec9e4"; // set random initialisation vector

	static encrypt(val) {
	  const encrypted = CryptoJS.AES.encrypt(val, this.IV).toString();
	  return encrypted;
	};
	
    static decrypt(encrypted) {
  		const decrypted = CryptoJS.AES.decrypt(encrypted, this.IV).toString(CryptoJS.enc.Utf8);
  		return decrypted;
	};

/*    static encrypt(val) {
        let cipher = this.crypto.createCipheriv('aes-256-cbc', this.ENC_KEY, this.IV);
        let encrypted = cipher.update(val, 'utf8', 'base64');
        encrypted += cipher.final('base64');
        return encrypted;
    };
*/
/*    static decrypt(encrypted) {
        let decipher = this.crypto.createDecipheriv('aes-256-cbc', this.ENC_KEY, this.IV);
        let decrypted = decipher.update(encrypted, 'base64', 'utf8');
        return (decrypted + decipher.final('utf8'));
    };
*/
    // ---------------------------------

    static alertError( error ) {
        if ( error.response && error.response.data && error.response.data.ExceptionCode) {
            const errorCode = collaborationExceptions.get(error.response.data.ExceptionCode);
            const translation = i18n.t('common:'+ errorCode);
            alert( translation);
        } else {
            alert( error );
        }
    }

    static generateToken(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    static getQueryParams( t ) {
        if( !t || "string"!=typeof t || t.length<2 ) {
            return new Map;
        } else {
            const params = t.split("&" ).map( t => {
                const pair = t.split("=");
                return [pair[0], pair[1]]
            });
            return new Map( params );
        }
    }

}

export default Context