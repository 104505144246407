/*
 *  RegisterPage.js
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
import React from "react";
import './RegisterPage.css'
import Context from "../context";
import { withTranslation } from 'react-i18next';

const RegisterPage = (props) => {
    const { t } = props;

    const doRegister = (event) => {
        const username = document.getElementById('username').value;
        if ( username == "" ) {
            alert( t("ENTER_USERNAME"))
            return;
        }

        const email = document.getElementById('email').value;
        var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test( email )){
            alert( t("INVALID_MAILFORMAT"))
            return;
        }

        const password = document.getElementById('password').value;
        var pwOk = true;
        if ( password.match( /[^a-zA-Z0-9#*+-~@$%&!]/ )) pwOk = false;
        if ( !password.match( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#|*|+|-|~|@|$|%|&])[0-9a-zA-Z#*+-~@$%&!]{8,}$/ )) pwOk = false;
        if ( !pwOk ) {
            alert( t("ENTER_VALID_PASSWORD"))
            return;
        }

        const passwordRpt = document.getElementById('passwordRpt').value;
        if ( password != passwordRpt ) {
            alert( t("PASSWORDS_DONT_MATCH"))
            return;
        }

        const activationKey = Context.generateToken( 16);

        var o = {
            username     : username,
            email        : email,
            password     : password,
            mailSubject  : t("ACCOUNT_ACTIVATION"),
            activationkey: activationKey,
            language     : "de_de",
            mailBody     : t("MAIL_BODY_REGISTER", { "LINK" : Context.frontendURL+ "activateAccount?activationKey="+ activationKey })
        };
        Context.api.post('/v1/users', o ).then( res => {
            alert( t("ACCOUNT_WAS_CREATED") );
            props.showHomePage();
        }).catch( ( error ) => {
            Context.alertError( error );
        });
    }

    return (
        <div id="divRegisterOuter" className="stddlg-outer">
            <div id="divRegister" className="stddlg">
                <form >
                    <p>
                        {t("USERNAME")}: <input type="text" name="username" id="username" required/>
                    </p>
                    <p>
                        {t("MAIL")}: <input type="text" name="email" id="email" required/>
                    </p>
                    <br/>
                    <div dangerouslySetInnerHTML={{__html: t('PASSWORD_RULES')}} />
                    <br/><br/>
                    <p>
                        {t("PASSWORD")}: <input type="password" name="password" id="password" required/>
                    </p>
                    <p>
                        {t("PASSWORD_RPT")}: <input type="password" name="passwordRpt" id="passwordRpt" required/>
                    </p>
                    <br/>
                    <p>
                        <input type="button" onClick={ () => doRegister() } value={t("CREATE_ACCOUNT")}/>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default withTranslation(['registerPage', 'common'] )(RegisterPage);
